import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PaymentMethodEnum, SearchRentalUnitsOrderEnum, UnitSearchOrderEnum } from '../generated/graphql';
import amelandTheme from '../themes/ameland';
import { BrandConfig } from './brandConfig';
import { CLASS_DESKMODE_BUTTON, CLASS_SEARCH_BOX_TRIP_GROUP_FIELD } from './customizationClassNames';
import { rentalUnitTypes } from './filterTypes';
import Phone from '../components/svg/socials/Phone.svg';
import Mail from '../components/svg/socials/Mail.svg';
import TranslatedPhoneNumber from '../components/TranslatedPhoneNumber';

const amelandConfig: BrandConfig = {
    name: 'Ameland',
    locales: ['nl', 'de', 'en'],
    theme: amelandTheme,
    globalStyling: `

    @media screen and (max-width: ${amelandTheme.mediaQueriesValues.m - 1}px) {
        .checkout-content-wrapper {
            padding-top: 3.2rem !important;
        }
    }

    .${CLASS_DESKMODE_BUTTON}{
        top: 12.4rem !important;
    }

    .${CLASS_SEARCH_BOX_TRIP_GROUP_FIELD} p {
        font-size: inherit;
    }
`,
    gtmID: {
        nl: 'GTM-TV6LKN',
        en: 'GTM-WGBFMS',
        de: 'GTM-TVRVRT',
    },
    hasReviews: true,
    
    locationDescription: {
        imgSrc: '/static/img/amelandTips.jpg',
        imgAlt: { nl: 'Dit is Ameland', en: 'This is Ameland', de: 'Das ist Ameland' },
        tips: {
            nl: [
                'Strandwandeling',
                'Het eiland ontdekken per fiets',
                'Beklim de vuurtoren',
                'Wadlopen over de bodem van de zee',
                'Bezoek de historische dorpjes',
            ],
            en: [
                'Beach walk',
                'Discover the island by bicycle',
                'Visit the lighthouse',
                'Mudflat walking',
                'Visit the historic villages',
            ],
            de: [
                'Strandspaziergang',
                'Die Insel mit dem Fahrrad entdecken',
                'Den Leuchtturm hochklettern',
                'Wattlaufen über den Meeresboden',
                'Die historischen Dörfer besuchen',
            ],
        },

        description: {
            nl: 'Of je nu voor een weekendje of lange vakantie naar Ameland komt, zodra je op de veerboot stapt ervaar je direct dat heerlijke vakantiegevoel. Geniet van wandelen, fietsen, eten & drinken, prachtige natuur, historische dorpen en natuurlijk het mooie strand. Voor zowel jong als oud is Ameland een ideaal eiland voor een heerlijke vakantie!',
            en: 'Whether you come to Ameland for a weekend or long holiday, as soon as you get on the ferry you will immediately experience the holiday feeling. Enjoy cycling, walking, the beautiful nature, the historic villages, eating, drinking and of course the beautiful beach. Ameland is the ideal island for both young and old!',
            de: 'Egal, ob Sie für ein Wochenende oder einen langen Urlaub nach Ameland kommen, sobald Sie auf die Fähre sind, werden Sie sofort dieses wundervolle Urlaubsgefühl erleben. Genießen Sie die Wanderwege, Radfahren, Essen und Trinken, wunderschöne Natur, historische Dörfer und natürlich den schönen Strand. Ameland ist eine ideale Insel für einen wunderschönen Urlaub für Jung und Alt!',
        },
    },

    template: {
        containerId: 'tor-wrapper',
        urls: {
            nl: 'https://vvvameland.nl/travelbase',
            en: 'https://vvvameland.com/travelbase',
            de: 'https://vvvameland.de/travelbase',
        },
        disableGTMWhenActive: true,
    },

    // stylesheet url in env, to allow different stylesheets to be included on different environments, as requested by Sterc
    searchBox: { styleSheetUrl: process.env.NEXT_PUBLIC_SEARCH_BOX_STYLESHEET_URL },
    planner: {
        styleSheetUrl: process.env.NEXT_PUBLIC_PLANNER_STYLESHEET_URL,
    },

    contact: {
        page: {
            nl: 'https://www.vvvameland.nl/over-ons/contact',
            de: 'https://www.vvvameland.de/ueber-uns/kontakt',
            en: 'https://www.vvvameland.com/about-us/contact',
        },
        info: [
            {
                name: (
                    <>
                        <FormattedMessage defaultMessage="Bel" />{' '}
                        <TranslatedPhoneNumber phoneNumber="+31 519 546 546" />
                    </>
                ),
                content: (
                    <FormattedMessage
                        defaultMessage="ma - vrij 9.30 - 16.30 uur {br} za 10.30 - 15.00 uur"
                        values={{ br: <br /> }}
                    />
                ),
                icon: <Phone width={24} height={24} />,
                link: 'tel:+31519546546',
            },
            {
                name: <FormattedMessage defaultMessage="E-mail" />,
                content: <FormattedMessage defaultMessage="Je krijgt binnen twee werkdagen een reactie." />,
                icon: <Mail width={24} height={24} />,
                link: 'mailto:info@vvvameland.nl',
                queryParams: {
                    nl: {
                        subject: 'Beste VVV Ameland, ik heb een vraag over {rentalUnitName}',
                    },
                    de: {
                        subject: 'Sehr geehrter Mitarbeiter des VVV Ameland, ich habe eine Frage zu {rentalUnitName}',
                    },
                    en: {
                        subject: 'Dear VVV Ameland employee, I have a question about {rentalUnitName}',
                    },
                },
            },
        ],
    },
    termsAndConditions: {
        nl: 'https://www.vvvameland.nl/algemene-voorwaarden',
        de: 'https://www.vvvameland.de/allgemeine-bedingungen-vvv',
        en: 'https://www.vvvameland.com/terms-and-conditions',
    },

    accommodationTypes: [
        {
            message: 'vacationHomes',
            value: rentalUnitTypes.HOME,
        },
        {
            message: 'caravan',
            value: `${rentalUnitTypes.HOME} type-of-accommodation.mobile-home type-of-accommodation.touring-caravan type-of-accommodation.chalet`,
        },
        {
            message: 'hotelOrBnB',
            value: rentalUnitTypes.ROOM,
        },
        {
            message: 'groupAccommodations',
            value: `${rentalUnitTypes.HOME} type-of-accommodation.group-accommodation`,
        },
        {
            message: 'camping',
            value: `${rentalUnitTypes.CAMPING_PITCH}`,
        },
        {
            message: 'tent',
            value: `${rentalUnitTypes.TENT}`,
        },

        {
            // not a valid filter, so make sure to escape the value when using it
            message: 'all',
            value: 'all',
        },
    ],
    lastMinute: {
        defaultAccommodationType: 'all',
    },
    groupTypes: ['adults', 'youth', 'children', 'babies', 'pets'],
    unitSearchOrder: {
        popularity: SearchRentalUnitsOrderEnum.RandomBoosted,
        ratingDesc: SearchRentalUnitsOrderEnum.ReviewRatingDesc,
    },
    tripsSearchOrder: {
        popularity: UnitSearchOrderEnum.RandomBoosted,
        ratingDesc: UnitSearchOrderEnum.ReviewRatingDesc,
        priceAsc: UnitSearchOrderEnum.PriceAsc,
        priceDesc: UnitSearchOrderEnum.PriceDesc,
    },

    // Map zoom level
    mapZoom: 14,

    // Map min zoom level
    mapMinZoom: {
        desktop: 10.5,
        mobile: 8.5,
    },

    coordinates: {
        latitude: 53.44729,
        longitude: 5.72783,
    },

    seo: {
        twitterUsername: '@vvvameland',
        siteName: 'VVV Ameland',
    },

    minBookingAge: 21,
    cancellationInsuranceDefault: true,

    checkout: {
        mobileHeaderPlacement: 'bottom',
        showBookingConfirmExtras: true,
        initialPaymentMethod: {
            nl: PaymentMethodEnum.Ideal,
            en: PaymentMethodEnum.CreditCard,
            de: PaymentMethodEnum.Paypal,
        },
        insuranceTerms: {
            nl: 'https://www.vvvameland.nl/uploads/bestanden/pdf/Voorwaarden/annuleringsregeling-vvvameland.pdf',
            en: 'https://www.vvvameland.nl/uploads/bestanden/pdf/Voorwaarden/cancellation-policy-vvvameland.pdf',
            de: 'https://www.vvvameland.nl/uploads/bestanden/pdf/Voorwaarden/reiserucktrittskostenregelung-vvvameland.pdf',
        },
    },

    complaintFormUrl: {
        nl: 'https://www.vvvameland.nl/over-ons/contact/contactformulier',
        de: 'https://www.vvvameland.de/ueber-uns/kontakt/kontaktformular',
        en: 'https://www.vvvameland.com/about-us/contact/contact-form',
    },
    homepageUrl: {
        nl: 'https://www.vvvameland.nl',
        de: 'https://www.vvvameland.de',
        en: 'https://www.vvvameland.com',
    },
    scripts: (
        <script type="text/javascript">
            {`if (navigator.userAgent.indexOf('GoNativeIOS/1.0 gonative') > -1) {
        window.location.href = 'gonative://ios/attconsent/status?callback=cb';

        function cb(result) {
            if (result.granted) {
                (function(w,d,s,l,i){ w[l]=w[l]||[];w[l].push({ 'gtm.start':
                new Date().getTime(),event:'gtm.js' });var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-TV6LKN');
            }
        }
    }`}
        </script>
    ),
    enableFavorites: true,

    bestelZuil: {
        redirectUrl: {
            nl: 'https://www.vvvameland.nl/activiteiten-en-evenementen',
            en: 'https://www.vvvameland.com/calendar/Activities-events',
            de: 'https://www.vvvameland.de/programm/aktivitaten-und-veranstaltungen',
        },
    },
};

export default amelandConfig;
